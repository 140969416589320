<template>
    <div>
        <div class="card p-3 mb-4">
            <h5 class="font-bold text-heading">Progress skripsi kamu</h5>
            <div class="flex w-full mt-2">
                <span class="circle-icon mr-2">
                    <img src="/icons/file.svg" alt="">
                </span>
                <div class="w-full">
                    <span class="text-theme mr-1 font-bold">{{ data.progress }}%</span>
                    <span>Menuju selesai</span>
                    <progress-bar class="mt-1" :value="data.progress" />
                </div>
            </div>
        </div>

        <b-row>
            <b-col sm="3">
                <list-schedule :data="data" />
            </b-col>
            <b-col sm="9">
                <div class="mt-4">
                    <list-bimbingan :data="data" redirect-route="DetailJadwalBimbingan" />
                    <router-link :to="{ name: 'DaftarBimbingan' }" class="btn btn-primary block">
                        <div class="flex items-center justify-center direction-column">
                            <Icon icon="mdi:plus-circle" class="text-lg" />
                            <span>Daftar Bimbingan</span>
                        </div>
                    </router-link>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { Icon } from '@iconify/vue2'
    import ProgressBar from '@/components/ProgressBar.vue'
    import ListSchedule from '@/components/Bimbingan/ListSchedule.vue'
    import ListBimbingan from '@/components/Bimbingan/ListBimbingan.vue'

    export default {
        name: 'PengajuanSkripsi',
        components: { Icon, ProgressBar, ListSchedule, ListBimbingan },
        computed: {
            ...mapState('mentors', { mentors: 'data' }),
            ...mapState('skripsi', { data: 'data', loading: 'loading' })
        },
        data () {
            return {
                form: {
                    data: {
                        title: '',
                        type: '',
                        mentors: [0, 0]
                    },
                    loading: true
                }
            }
        },
        methods: {
            ...mapActions('mentors', { fetchMentors: 'fetch' }),
            ...mapActions('skripsi', { createSkripsi: 'create', fetchSkripsi: 'fetch' }),
            submit () {
                this.form.loading = true
                this.createSkripsi(this.form.data)
                    .then(() => {
                        this.$swal({
                            imageUrl: require('@/assets/images/skripsi-created.png'),
                            imageWidth: 400,
                            title: 'Pengajuan Skripsi',
                            text: 'Judul Skripsi anda sedang tahap approval, mohon menunggu untuk lanjut ketahap selanjutnya'
                        })
                        this.fetchSkripsi()
                    })
                    .catch(() => {
                        this.$swal({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something Wrong'
                        })
                    })
                    .finally(() => {
                        this.form.loading = false
                    })
            },
            onMentorChange (e, index) {
                this.form.data.mentors[index] = e.target.value
            },
            addMentor () {
                this.form.data.mentors.push(0)
            },
            removeMentor (index) {
                this.form.data.mentors.splice(index, 1)
            }
        },
        mounted () {
            this.fetchMentors()
        }
    }
</script>
