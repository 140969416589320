<template>
    <div>
        <h5 class="font-bold">Jadwal</h5>
        <div v-for="(item, index) in schedule" :key="index" class="card p-3 mt-2">
            <h5 class="font-bold color-primary">{{ item.title }}</h5>
            <p class="font-bold">{{ item.mentor }}</p>
            <p class="flex items-center">
                <Icon icon="feather:calendar" class="mr-1" /> {{ formatDate(item.date) }}
                <Icon icon="feather:clock" class="mr-1 ml-2" /> {{ formatTime(item.date) }}
            </p>
            <p v-if="item.location" class="flex items-center"><Icon icon="feather:map-pin" class="mr-1" /> {{ item.location }}</p>
        </div>
        <div v-if="schedule.length < 1" class="card p-3 mt-2">
            <p class="m-0 text-center">Tidak Ada Jadwal</p>
        </div>
    </div>
</template>

<script>
    import { Icon } from '@iconify/vue2'

    export default {
        components: { Icon },
        props: {
            data: {
                type: Object,
                default: () => ({
                    bimbingan: [],
                    mentors: []
                })
            }
        },
        computed: {
            schedule () {
                const schedule = []

                if (!this.data || !this.data.bimbingan) return schedule

                this.data.bimbingan.forEach(item => {
                    item.mentors.forEach((mentor, index) => {
                        if (!mentor.finished && mentor.status === 2) {
                            schedule.push({
                                title: item.title,
                                mentor: `Pembimbing ${index + 1}`,
                                date: mentor.date,
                                location: mentor.location
                            })
                        }
                    })
                })

                return schedule
            }
        }
    }
</script>
