<template>
    <div>
        <router-link v-for="i in data.bimbingan" :key="i.id" :to="{ name: redirectRoute, params: { id: i.id } }">
            <div class="card p-3 mb-3 w-full">
                <div class="flex justify-between">
                    <div class="flex items-center">
                        <span class="circle-icon mr-2" style="background-color: #2E74B0">
                            <img src="/icons/copy.svg" alt="">
                        </span>
                        <div class="ml-2">
                            <h5 class="mb-0 font-bold">{{ i.title }}</h5>
                            <div class="mt-2">
                                <p v-for="(mentor, index) in i.mentors" :key="mentor.id" class="m-0 small list-mentor color-dark">
                                    Pembimbing {{ index + 1 }}: <strong>{{ formatDate(mentor.date, 'D MMMM YYYY') }}</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <span class="pill" :class="`pill-color-status-${i.status_finished}`">{{ i.status_finished_text }}</span>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
    export default {
        props: {
            data: {
                type: Object,
                default: () => ({
                    bimbingan: [],
                    mentors: []
                })
            },
            redirectRoute: {
                type: String,
                default: ''
            }
        }
    }
</script>
